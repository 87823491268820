import * as React from "react"
import QRRedirect from "../../components/qrRedirect"


const QR_BoothBanner = () => {
  return (
    <main>
      <title>Redirecting...</title>
      <QRRedirect source="booth-banner" destination="/subscribe" />
    </main>
  )
}

export default QR_BoothBanner